import React from "react"
import { Link } from "gatsby"
//import {Helmet} from "react-helmet";

import Layout from "../components/layout"
//import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1>Welcome Vehicle Owners from the Future</h1>
    <p><a href="https://en.wikipedia.org/wiki/Oh_Brother,_Where_Art_Thou%3F"><img title="Future Car" src={'TheHomer.jpg'} /></a></p>
    <p>This website provides information on mood enhancement and reliability modifications for future-car products.</p>
    <h2>Send ideas</h2>
    <p><a href="https://form.jotform.com/92566618566168" className="btn lightbox-92566618566168" style={{marginTop: '16px'}}>Product Enhancement Feedback</a></p>
    <h2>Feature Focus</h2>
        <ul>
            <li>Reliability
                <ul>
                    <li>Automatic lift gates</li>
                </ul>
            </li>
            <li>Audio Pollution
                <ul>
                    <li>Beeping</li>
                </ul>
            </li>
            <li>Light Pollution
                <ul>
                    <li>Approach lighting</li>
                    <li>Welcome lighting</li>
                </ul>
            </li>
        <li>Eyes-Free Interfaces
            <ul>
                <li>Tactile interfaces</li>
            </ul>
        </li>
        </ul>
    <h2>Make and Model Information</h2>
        <ul>
            <li><Link to="/subaru-outback/">Subaru Outback</Link></li>
        </ul>
{/*
    <Helmet>
            <script src="https://form.jotform.com/static/feedback2.js" type="text/javascript"></script>
    </Helmet>
    <Helmet>
            <script type="text/javascript">
                {` var JFL_92566618566168 = new JotformFeedback({ formId: '92566618566168', base: 'https://form.jotform.com/', windowTitle: 'Product Enhancement Feedback', background: '#FFA500', fontColor: '#FFFFFF', type: 'false', height: 500, width: 700, openOnLoad: false }); `}
            </script>
    </Helmet> 
*/}
  </Layout>
)

export default IndexPage
